.App {
  text-align: center;
}
a {
  text-decoration: none;
  color: #000;
}
input,
div,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bold {
  font-weight: 700;
}
.image-columns {
  justify-content: space-between;
  flex-wrap: wrap;
  columns: 2 200px;
  column-gap: 1rem;
  padding: 0 100px;
}

header {
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transition: height 0.25s linear, font-size 0.25s linear, flex 0.25s linear,
    font-weight 0.25s linear;
  top: 0;
  width: 100%;
  padding: 0 4rem;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background: #fff;
  z-index: 5;
}

header > h1 {
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
  font-weight: 400;
  font-size: inherit;
  font-family: Roboto, sans-serif;
}
header h1 span {
  font-weight: 300;
}

.navigation-desktop {
  letter-spacing: 0.2rem;
}

header.expanded {
  height: 10rem;
  font-size: 2rem;
}

header.collapsed {
  height: 3.125rem;
  font-size: 1.4rem;
}

.contact-link {
  width: auto;
  flex-grow: 0;
}
.contact-link.expanded {
  width: auto;
}
nav span {
  margin: 0 1.5rem;
}
nav a {
  padding: 0.1rem 0;
}
nav a:hover {
  border-bottom: 0.15rem solid #000;
}
.list-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between;
}
.list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
  margin-bottom: 6rem;
  width: calc(33.333% - 0.5rem);
}

.list-item > span,
.list-item-subcategory {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 1.2rem 1rem 0.4rem 1rem;
  text-align: left;
}
.list-item-subcategory {
  font-weight: 300;
  color: #cecece;
  padding-top: 0;
}
.list-item img {
  max-width: 100%;
  vertical-align: middle;
  filter: grayscale(100%);
  transition: filter 0.1s linear;
}
.list-item img:hover {
  filter: unset;
  transition: filter 0.1s linear;
}
.detail-wrap {
  padding: 6rem;
}
.detail-dropdown {
  display: flex;
  justify-content: space-between;
}

.detail-dropdown-heading div {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.detail-dropdown-heading > div:first-child {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.detail-dropdown-heading > div:last-child {
  font-size: 1.3rem;
  font-weight: 300;
  color: #cecece;
}

.plus-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.plus-wrapper .closed .vertical {
  transition: all 0.25s ease-in-out;
  transform: rotate(-90deg);
  background-color: #000;
}
.plus-wrapper .closed .horizontal {
  transition: all 0.25s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
  background-color: #000 !important;
}

.plus-wrapper .opened .vertical {
  transition: all 0.25s ease-in-out;
  transform: rotate(90deg);
  background-color: #000 !important;
}

.plus-wrapper .opened .horizontal {
  transition: all 0.25s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
  background-color: #000 !important;
}

.plus-wrapper .circle-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  height: 3rem;
  width: 3rem;
  font-size: 1em;
}

.plus-wrapper .circle-plus .circle {
  position: relative;
  width: 2.55em;
  height: 2.5em;
  border-radius: 100%;
}
.plus-wrapper .circle-plus .circle .horizontal {
  position: absolute;
  width: 30px;
  height: 5px;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  margin-top: -2.5px;
}
.plus-wrapper .circle-plus .circle .vertical {
  position: absolute;
  width: 5px;
  height: 30px;
  left: 50%;
  margin-left: -2.5px;
  top: 50%;
  margin-top: -15px;
}
.detail-dropdown-text {
  text-align: left;
  line-height: 1.4;
  margin: 2rem 0 0 0;
  width: 100%;
  overflow: hidden;
  font-size: 1.2rem;
  font-weight: 300;
}
.detail-dropdown-info-text a:hover {
  text-decoration: underline;
}
.detail-dropdown-text.closed {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s linear, opacity 0.4s ease-out;
}
.detail-dropdown-text.opened {
  max-height: 35rem;
  opacity: 1;
  transition: max-height 0.4s linear, opacity 0.4s ease-out;
}
.detail-images img {
  cursor: pointer;
}
.detail-image-wrapper {
  position: relative;
}
.detail-image-text {
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  text-align: left;
  opacity: 0;
  position: absolute;
  z-index: -10;
  top: 0;
  transition: opacity 0.5s linear, top 0.3s linear;
  pointer-events: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.3;
  letter-spacing: 0.05rem;
}
.detail-image-text.active {
  z-index: 1;
  opacity: 1;
  top: 1.2rem;
  transition: opacity 0.5s ease-in, top 0.3s linear;
}
.detail-images.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.detail-images.flex img {
  width: calc(50% - 0.5rem);
  padding-bottom: 1rem;
}
.detail-images.columns {
  display: block;
  position: relative;
  height: calc(100% + 1rem);
  columns: 2 auto;
}
.detail-images.columns img {
  display: inline-block;
  position: relative;
  width: calc(100% - 0.5rem);
  padding-bottom: 1rem;
  bottom: -1rem;
}
.detail-dropdown-entry {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0;
}
.detail-dropdown-entry > div:first-child {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
}
.detail-dropdown-entry > div:last-child {
  font-size: 1.2rem;
  font-weight: 300;
  color: #000;
}

.contact-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 5rem;
  width: 100%;
  top: -4rem;
  box-sizing: border-box;
}
.contact-wrap img {
  width: 20%;
  margin-bottom: 5rem;
}
.contact-info {
  display: flex;
  justify-content: space-between;
  line-height: 1.4;
  font-size: 1.1rem;
}
.contact-info > div:first-child {
  flex: 0 0 35%;
}
.contact-info > div:nth-child(2) {
  flex: 0 0 27%;
}
.contact-info > div:last-child {
  flex: 0 0 35%;
}
.contact-block {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 3rem;
  box-sizing: border-box;
}
.contact-block > div:first-child {
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.contact-block a {
  display: block;
  line-height: 1.6;
}

.contact-block a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  header {
    padding: 0 1rem;
    font-size: 0.5rem;
  }

  .detail-wrap {
    padding: 2rem;
  }

  .detail-images.columns img {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 1rem;
    bottom: -1rem;
  }
  .detail-dropdown-text.opened {
    max-height: 100rem;
    opacity: 1;
    transition: max-height 0.4s linear, opacity 0.4s ease-out;
  }

  .list-item {
    width: calc(50% - 0.5rem);
  }
}
.hamburger-wrap {
  display: none;
}
.navigation-mobile {
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
}
.navigation-mobile > nav {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: min-content;
  width: min-content;
  text-align: left;
  padding: 0.2rem 0;
  border-bottom-left-radius: 0.2rem;
}
.navigation-mobile > nav a {
  padding: 0.65rem 2rem;
}
.navigation-mobile > nav a:first-child {
  border-bottom: 1px solid gray;
}
.navigation-mobile > nav a:last-child {
  border-top: 1px solid gray;
}
@media screen and (max-width: 1100px) {
  .contact-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 2rem;
    top: -4rem;
  }
  .contact-block {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 900px) {
  .hamburger-wrap {
    display: block;
  }
  .navigation-desktop {
    display: none;
  }
  nav a:hover {
    text-decoration: none;
  }
}
@media screen and (max-width: 800px) {
  .contact-wrap {
    padding: 0 2rem;
  }
  .contact-block {
    padding: 1.5rem 1rem;
  }
  .contact-info {
    flex-direction: column;
  }
  .contact-wrap img {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .detail-images.columns {
    display: block;
    position: relative;
    height: calc(100% + 1rem);
    columns: 1 auto;
  }
  .detail-image-wrapper {
    pointer-events: none;
  }

  .list-item {
    width: 100%;
  }
}
@media screen and (max-width: 460px) {
  .detail-wrap {
    padding: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .detail-wrap {
    padding: 1rem;
  }
}
